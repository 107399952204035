import { Button } from '@aether/ui/Button';
import { Card } from '@aether/ui/Card';
import { Link, useNavigate } from 'react-router-dom';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useActionCode } from '@/utils/auth';

export function ActionVerifyEmail({ actionCode, continueUrl }: { actionCode: string; continueUrl: string }) {
  const { data, loading, error } = useActionCode(actionCode);
  const decodedContinueURL = new URL(decodeURIComponent(continueUrl));
  const email = decodedContinueURL.searchParams.get('email')!;
  const redirect = decodedContinueURL.searchParams.get('redirectTo');
  const navigate = useNavigate();

  const redirectTo =
    data?.data?.applyActionCode.success && data?.data?.applyActionCode.navigate
      ? data.data.applyActionCode.navigate
      : redirect || '/welcome';

  return (
    <>
      {error ? (
        <Card variant="hero" title="Error." className="m-20 -mx-4 max-w-4xl sm:mx-auto">
          <div className="flex flex-col gap-4">
            <p>Failed to verify your email address ({email}).</p>
            <ApiErrorAlert error={error} />
            <div>
              <Button asChild>
                <Link to={`/auth/email-verification`}>Request another verification e-mail</Link>
              </Button>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          variant="hero"
          title="Your email has been verified."
          className="-mx-4 max-w-3xl sm:mx-auto"
          loading={loading}
        >
          <div className="flex flex-col gap-4">
            <p>You may now log in to your account</p>
            <div>
              <Button asChild>
                <Link
                  to={`/auth/login?email=${encodeURIComponent(email)}&redirectTo=${encodeURIComponent(redirectTo)}`}
                >
                  Go to Log in
                </Link>
              </Button>
            </div>
          </div>
        </Card>
      )}
      <ConfirmDialog
        alertDialogRootProps={{ open: data?.data?.applyActionCode.success === false }}
        title="We encountered an error"
        body={data?.data?.applyActionCode.message || 'The action you performed was unsuccessful, please try again.'}
        onConfirm={() => navigate(`/auth/login?email=${email}&redirectTo=${encodeURIComponent(redirectTo)}`)}
        onCancel={() => navigate(`/auth/login?email=${email}&redirectTo=${encodeURIComponent(redirectTo)}`)}
        confirmText="Continue"
        cancelProps={{ className: 'hidden' }}
      />
    </>
  );
}
