import * as React from 'react';

import { Input } from '../Input';
import { cn, useForwardedRef, useId } from '../utils';

export type TextFieldProps = Omit<React.ComponentProps<typeof Input.Field>, 'onChange' | 'className' | 'onKeyDown'> & {
  label?: React.ReactNode;
  description?: React.ReactNode;
  wrapperClassName?: string;
  labelClassName?: string;
  fieldClassName?: string;
  fieldGroupClassName?: string;
  errorClassName?: string;
  descriptionClassName?: string;
  iconBeforeClassName?: string;
  iconBefore?: string;
  insertBefore?: React.ReactNode;
  insertAfter?: React.ReactNode;
  errorMessage?: React.ReactNode;
  onChange?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>, value: string) => void;
};

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField(props, forwardedRef) {
  const {
    id,
    label,
    description,
    errorMessage,
    wrapperClassName,
    labelClassName,
    fieldClassName,
    fieldGroupClassName,
    errorClassName,
    descriptionClassName,
    iconBefore,
    iconBeforeClassName,
    insertBefore,
    insertAfter,
    onKeyDown = () => undefined,
    onChange = () => undefined,
    required,
    variant,
    density,
    ...inputProps
  } = props;
  const inputRef = useForwardedRef(forwardedRef);
  const inputId = useId(id);
  return (
    <Input.Wrapper className={wrapperClassName}>
      {label && (
        <Input.Label density={density} htmlFor={inputId} id={`${inputId}-label`} className={labelClassName}>
          {label}
        </Input.Label>
      )}
      <Input.FieldGroup className={fieldGroupClassName} variant={variant} density={density} error={!!errorMessage}>
        {iconBefore && (
          <Input.Icon
            density={density}
            error={!!errorMessage}
            className={cn('mx-1', iconBeforeClassName)}
            icon={iconBefore}
            onClick={() => inputRef.current.focus()}
          />
        )}
        {insertBefore}
        <Input.Field
          {...inputProps}
          variant={variant}
          density={density}
          onChange={e => onChange(e.currentTarget.value)}
          onKeyDown={e => onKeyDown(e, inputRef.current.value)}
          id={inputId}
          ref={inputRef}
          aria-required={required || undefined}
          aria-labelledby={(label && `${inputId}-label`) || undefined}
          aria-describedby={(description && `${inputId}-description`) || undefined}
          aria-errormessage={(errorMessage && `${inputId}-error`) || undefined}
          className={cn('flex-1', inputProps.disabled && 'cursor-not-allowed', fieldClassName)}
        />
        {insertAfter}
      </Input.FieldGroup>
      {errorMessage && (
        <Input.ErrorMessage id={`${inputId}-error`} className={errorClassName}>
          {errorMessage}
        </Input.ErrorMessage>
      )}
      {description && (
        <Input.Description id={`${inputId}-description`} className={descriptionClassName}>
          {description}
        </Input.Description>
      )}
    </Input.Wrapper>
  );
});
