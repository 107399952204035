import { cloneElement, useState, type ComponentProps, type MouseEventHandler } from 'react';
import { Alert } from '@aether/ui/Alert';
import { AlertDialog, type AlertDialogRootProps } from '@aether/ui/AlertDialog';
import { Button } from '@aether/ui/Button';

type ButtonProps = ComponentProps<typeof Button>;

export const ConfirmDialog = ({
  children,
  title,
  body,
  cancelText = 'Cancel',
  cancelProps = {},
  confirmText = 'Yes',
  confirmProps = {},
  onConfirm,
  onCancel = () => {},
  alertDialogRootProps,
  contentClassName,
}: {
  children?: JSX.Element;
  title: string;
  body: string | JSX.Element;
  cancelText?: string;
  cancelProps?: ButtonProps;
  confirmText?: string;
  confirmProps?: ButtonProps;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  alertDialogRootProps?: AlertDialogRootProps;
  contentClassName?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const trigger = children && cloneElement(children);
  const cancelButtonProps: ButtonProps = {
    variant: 'outline',
    variantColor: 'accent',
    disabled: isLoading,
    ...cancelProps,
  };
  const confirmButtonProps: ButtonProps = {
    variantColor: 'accent',
    loading: isLoading,
    ...confirmProps,
    onClick: async event => {
      setIsLoading(true);
      setError(null);
      try {
        await onConfirm(event);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
  };

  return (
    <AlertDialog.Root {...alertDialogRootProps}>
      {trigger && <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>}
      <AlertDialog.Content overlayOpacity="bg-opacity-50" className={contentClassName}>
        <AlertDialog.Title className="text-lg font-medium">{title}</AlertDialog.Title>
        <AlertDialog.Body>
          <div className="flex flex-col gap-4">
            <div className="min-h-[100px]">
              {typeof body === 'string' ? <AlertDialog.Description>{body}</AlertDialog.Description> : body}
              {error && <Alert level="error">{error?.message}</Alert>}
            </div>
          </div>
        </AlertDialog.Body>
        <div className="flex justify-end gap-2">
          <AlertDialog.Cancel variant="unstyled" asChild onClick={onCancel}>
            <Button {...cancelButtonProps}>{cancelText}</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button {...confirmButtonProps}>{confirmText}</Button>
          </AlertDialog.Action>
        </div>
        <AlertDialog.Cancel onClick={onCancel} disabled={isLoading} />
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
