import * as React from 'react';
import { cx, type CxOptions } from 'class-variance-authority';
import { extendTailwindMerge } from 'tailwind-merge';

import { theme } from './theme';

/**
 * Tailwind Merge config
 * https://github.com/dcastil/tailwind-merge/blob/v2.0.0/docs/configuration.md
 */
const customTwMerge = extendTailwindMerge({
  extend: {
    theme: {
      colors: Object.keys(theme.colors),
    },
    classGroups: {
      'font-size': [
        {
          text: [
            'xxs',
            'hero',
            'home',
            'heading-1',
            'heading-2',
            'heading-3',
            'heading-4',
            'heading-5',
            'heading-6',
            'body',
            'body-large',
            'body-small',
            'card-title',
            'title-1',
            'small',
            'overline-1',
            'overline-2',
          ],
        },
      ],
    },
  },
});

/**
 * Smart merge of tailwind classes avoiding specificity issues
 */
export function cn(...inputs: CxOptions) {
  return customTwMerge(cx(inputs));
}

/**
 * Generate a unique ID for a component if none is provided.
 */
export function useId(defaultId?: string) {
  const id = React.useId();
  return defaultId || `aether-generated-${id}`;
}

/**
 * Provides a controlled input state for uncontrolled components that still need to be able to change the state.
 * Example: adding a button in the field to clear the input.
 *
 */
export { useControlledState } from '@react-stately/utils';

/**
 * useRef, but merges with a given forwarded ref if existing.
 *
 * Copied from @react-aria/utils useObjectRef.
 */
export function useForwardedRef<T>(
  forwardedRef?: ((instance: T | null) => void) | React.MutableRefObject<T | null> | null
): React.MutableRefObject<T> {
  const objRef = React.useRef<T>();
  // @ts-expect-error -- Not sure why the types complain for us, but it is known to be reliable so we can safely ignore the errors.
  return React.useMemo(
    () => ({
      get current() {
        return objRef.current;
      },
      set current(value) {
        objRef.current = value;
        if (typeof forwardedRef === 'function') {
          // @ts-expect-error -- same as above
          forwardedRef(value);
        } else if (forwardedRef) {
          // @ts-expect-error -- same as above
          forwardedRef.current = value;
        }
      },
    }),
    [forwardedRef]
  );
}

interface Filter {
  /** Returns whether a string starts with a given substring. */
  startsWith(string: string, substring: string): boolean;
  /** Returns whether a string ends with a given substring. */
  endsWith(string: string, substring: string): boolean;
  /** Returns whether a string contains a given substring. */
  contains(string: string, substring: string): boolean;
}

/**
 * Provides localized string search functionality that is useful for filtering or matching items
 * in a list. Options can be provided to adjust the sensitivity to case, diacritics, and other parameters.
 *
 * Copied from @react-aria/i18n.
 */
export function useFilter(options?: Intl.CollatorOptions): Filter {
  const collator = useCollator({
    usage: 'search',
    ...options,
  });

  // TODO(later): these methods don't currently support the ignorePunctuation option.
  const startsWith = React.useCallback(
    (string: string, substring: string) => {
      if (substring.length === 0) {
        return true;
      }

      // Normalize both strings so we can slice safely
      // TODO: take into account the ignorePunctuation option as well...
      string = string.normalize('NFC');
      substring = substring.normalize('NFC');
      return collator.compare(string.slice(0, substring.length), substring) === 0;
    },
    [collator]
  );

  const endsWith = React.useCallback(
    (string: string, substring: string) => {
      if (substring.length === 0) {
        return true;
      }

      string = string.normalize('NFC');
      substring = substring.normalize('NFC');
      return collator.compare(string.slice(-substring.length), substring) === 0;
    },
    [collator]
  );

  const contains = React.useCallback(
    (string: string, substring: string) => {
      if (substring.length === 0) {
        return true;
      }

      string = string.normalize('NFC');
      substring = substring.normalize('NFC');

      let scan = 0;
      const sliceLen = substring.length;
      for (; scan + sliceLen <= string.length; scan++) {
        const slice = string.slice(scan, scan + sliceLen);
        if (collator.compare(substring, slice) === 0) {
          return true;
        }
      }

      return false;
    },
    [collator]
  );

  return React.useMemo(
    () => ({
      startsWith,
      endsWith,
      contains,
    }),
    [startsWith, endsWith, contains]
  );
}

const collatorCache = new Map<string, Intl.Collator>();

/**
 * Provides localized string collation for the current locale. Automatically updates when the locale changes,
 * and handles caching of the collator for performance.
 *
 * Copied from @react-aria/i18n.
 */
export function useCollator(options?: Intl.CollatorOptions): Intl.Collator {
  const locale = 'en-UK';

  const cacheKey =
    locale +
    (options
      ? Object.entries(options)
          .sort((a, b) => (a[0] < b[0] ? -1 : 1))
          .join()
      : '');
  if (collatorCache.has(cacheKey)) {
    return collatorCache.get(cacheKey)!;
  }

  const formatter = new Intl.Collator(locale, options);
  collatorCache.set(cacheKey, formatter);
  return formatter;
}

/**
 * Used for chart.js axis labels which support multiple lines if provided a string array for the label.
 *
 * @param {string} label The label to be displayed on the axis
 * @param {number} [newLineCharactersThreshold=14] Splits into a new line when the text exceeds this number of characters
 * @returns {string[]}
 */
export const multiLineLabel = (label: string, newLineCharactersThreshold = 14) => {
  const words = label.split(' ');
  let line = [];
  const lines = [];

  while (words.length) {
    const word = words.shift();

    if (line.length === 0 || line.join('').length + (word?.length || 0) <= newLineCharactersThreshold) {
      line.push(word);
    } else {
      lines.push(line.join(' '));
      if (word) {
        words.unshift(word);
      }
      line = [];
    }
  }
  if (line.length) {
    lines.push(line.join(' '));
  }

  return lines;
};
