import * as React from 'react';
import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import { cva, type VariantProps } from 'class-variance-authority';

import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { cn } from '../utils';

export type AlertDialogRootProps = RadixAlertDialog.DialogProps;
const Root = RadixAlertDialog.Root;

export type AlertDialogTriggerProps = RadixAlertDialog.DialogTriggerProps;
const Trigger = RadixAlertDialog.Trigger;

export type AlertDialogDescriptionProps = RadixAlertDialog.DialogDescriptionProps;
const Description = RadixAlertDialog.Description;

export type AlertDialogContentProps = RadixAlertDialog.DialogContentProps &
  VariantProps<typeof dialogStyles> & { overlayOpacity?: 'bg-opacity-5' | 'bg-opacity-50' };
function Content(props: AlertDialogContentProps) {
  const { className, children, variant, fullscreen, overlayOpacity = 'bg-opacity-5', ...rest } = props;
  return (
    <RadixAlertDialog.Portal>
      <RadixAlertDialog.Overlay className={cn('fixed inset-0 z-40 bg-black', overlayOpacity)} />
      <RadixAlertDialog.Content {...rest} className={cn(dialogStyles({ variant, fullscreen }), className)}>
        {children}
      </RadixAlertDialog.Content>
    </RadixAlertDialog.Portal>
  );
}

const dialogStyles = cva('fixed left-1/2 top-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 animate-dialog flex-col', {
  variants: {
    variant: {
      unstyled: '',
      default: 'rounded bg-white p-6 shadow-card',
    },
    fullscreen: {
      true: 'h-screen max-h-screen w-screen',
      false: 'w-90vw max-h-[85vh] max-w-[450px]',
    },
  },
  defaultVariants: {
    variant: 'default',
    fullscreen: false,
  },
});

export type AlertDialogBodyProps = React.ComponentProps<'div'>;
function Body(props: AlertDialogBodyProps) {
  const { className, ...rest } = props;
  return <div className={cn('-mx-6 my-4 max-h-full overflow-auto px-6', className)} {...rest} />;
}

export type AlertDialogTitleProps = RadixAlertDialog.DialogTitleProps & VariantProps<typeof titleStyle>;
const Title = React.forwardRef<HTMLHeadingElement, AlertDialogTitleProps>(function Title(props, ref) {
  const { className, variant, ...rest } = props;
  return <RadixAlertDialog.Title ref={ref} className={cn(titleStyle({ variant }), className)} {...rest} />;
});
const titleStyle = cva('', {
  variants: {
    variant: {
      default: 'text-xl font-medium',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type AlertDialogCancelProps = RadixAlertDialog.AlertDialogCancelProps & VariantProps<typeof closeStyle>;
const Cancel = React.forwardRef<HTMLButtonElement, AlertDialogCancelProps>(function Close(props, ref) {
  const {
    className,
    children = <IconButton size="small" variant="ghost" variantColor="secondary" icon="ic:outline-close" />,
    variant,
    ...rest
  } = props;
  return (
    <RadixAlertDialog.Cancel ref={ref} asChild className={cn(closeStyle({ variant }), className)} {...rest}>
      {children}
    </RadixAlertDialog.Cancel>
  );
});
const closeStyle = cva('', {
  variants: {
    variant: {
      default: 'absolute right-6 top-6',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
const Action = React.forwardRef<HTMLButtonElement, RadixAlertDialog.AlertDialogActionProps>(
  function Action(props, ref) {
    const { className, children = <Button variantColor="accent">Accept</Button>, ...rest } = props;
    return (
      <RadixAlertDialog.Action ref={ref} asChild className={className} {...rest}>
        {children}
      </RadixAlertDialog.Action>
    );
  }
);

export const AlertDialog = { Root, Trigger, Title, Content, Body, Cancel, Action, Description };
