import * as React from 'react';
import { tracker } from '@aether/tracking';
import { DisclaimerBanner } from '@aether/tracking/disclaimer-banner';
import { Outlet, useLocation } from 'react-router-dom';

import { AuthController } from '../controllers/AuthController';
import { CountryController } from '../controllers/CountryController';
import { PageLoaderController } from '../controllers/PageLoaderController';

export default function RootLayout() {
  return (
    <>
      <Outlet />
      <PageLoaderController />
      <AuthController />
      <CountryController />
      <DisclaimerBanner />
      <PageViewTracker />
    </>
  );
}

function PageViewTracker() {
  const location = useLocation();
  React.useEffect(() => {
    tracker.capture('$pageview');
  }, [location]);
  return null;
}
