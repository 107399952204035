import * as React from 'react';
import { blockedCountriesISO, countryNameToISO2 } from '@aether/taxonomy';
import { Alert } from '@aether/ui/Alert';
import { Button } from '@aether/ui/Button';
import { Card } from '@aether/ui/Card';
import { Checkbox } from '@aether/ui/Checkbox';
import { Icon } from '@aether/ui/Icon';
import { Input } from '@aether/ui/Input';
import { PasswordField } from '@aether/ui/PasswordField';
import { SelectField, type SelectFieldOption } from '@aether/ui/SelectField';
import { TextField } from '@aether/ui/TextField';
import { Tooltip } from '@aether/ui/Tooltip';
import { getWebsiteOrigin } from '@aether/utils/services/linking-service';
import { signUpSchema } from '@aether/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { PageSeoTitle } from '@/components/PageSeoTitle';
import { useAuth } from '@/utils/auth';

import { AuthLink } from '../components/AuthLink';

const countryOptions: SelectFieldOption[] = Object.entries(countryNameToISO2)
  .reduce((acc: SelectFieldOption[], [countryName, isoCode]) => {
    if (!blockedCountriesISO.includes(isoCode)) {
      acc.push({
        label: countryName,
        value: isoCode,
      });
    }
    return acc;
  }, [])
  .sort((x, y) => (x.label < y.label ? -1 : 1));

export enum InvestorProfile {
  Professional = 'Professional',
  NonProfessional = 'Non-professional',
}

export const profileOptions: SelectFieldOption[] = [
  {
    label: 'Professional investor',
    value: InvestorProfile.Professional,
  },
  {
    label: 'Non-professional investor',
    value: InvestorProfile.NonProfessional,
  },
];

const formSchema = signUpSchema.extend({
  terms: z.boolean().refine(value => Boolean(value), { message: 'You must accept the terms and conditions' }),
});

type FormValues = z.infer<typeof formSchema>;

export const Register = () => {
  const turnstileRef = React.useRef<TurnstileInstance | undefined>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [params] = useSearchParams();
  const [error, setError] = React.useState<any>();
  const getDragonURL = getWebsiteOrigin();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      displayName: '',
      declaredOrg: '',
      country: '',
      password: '',
      captchaResponse: 'SOME_MOCK_RESPONSE',
      profession: InvestorProfile.Professional,
      terms: false,
    },
  });

  const { user, register } = useAuth();

  if (user && user.role !== 'public') {
    if (user.emailVerified) {
      // User already has a verified account, redirect to homepage
      setTimeout(() => {
        navigate(params.get('redirectTo') || '/', { replace: true });
      });
    } else {
      navigate('/auth/email-verification', { replace: true });
      return <Card loading={true} variant="unstyled" />;
    }
  }

  const signUp = async (values: FormValues) => {
    const { terms, ...rest } = values;
    setError(undefined);
    try {
      if (state?.afterAuth) rest.afterAuth = state.afterAuth;
      await register(rest);
    } catch (e: any) {
      setError(e);
    }
  };

  return (
    <Card
      variant="hero"
      title="Request access."
      data-testid="ac-AccessPage"
      className="m-20 -mx-4 max-w-4xl sm:mx-auto"
    >
      <PageSeoTitle title="Request access" />
      <div className="flex flex-col gap-8">
        <p className="text-grey-500">
          Already have an account?{' '}
          <AuthLink
            to={`/auth/login?redirectTo=${encodeURIComponent(params.get('redirectTo') || '')}`}
            className="whitespace-nowrap"
          >
            Back to sign-in.
          </AuthLink>
        </p>
        <form onSubmit={handleSubmit(signUp)} className="flex flex-col gap-4">
          <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
            <div className="flex flex-col gap-4">
              <Controller
                control={control}
                name="displayName"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="displayName"
                    label="Full name"
                    {...field}
                    autoFocus
                    placeholder='e.g. "Jane Smith"'
                    iconBefore="ic:outline-person"
                    errorMessage={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="email"
                    label="Email"
                    {...field}
                    iconBefore="ic:outline-email"
                    inputMode="email"
                    placeholder="you@company.com"
                    errorMessage={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <PasswordField
                    id="password"
                    label="Password"
                    {...field}
                    iconBefore="ic:outline-key"
                    autoComplete="new-password"
                    placeholder="Set a password"
                    errorMessage={error?.message}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-4">
              <Controller
                control={control}
                name="profession"
                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                  <SelectField
                    id="profession"
                    label={
                      <>
                        Client type
                        <Tooltip
                          content={
                            'We currently only accept requests from professional clients. If you have any questions, please get in touch with us at support@arabesque.ai.'
                          }
                          asChild
                        >
                          <Icon className="flex-0" icon="ic:outline-info" />
                        </Tooltip>
                      </>
                    }
                    {...field}
                    labelClassName="inline-flex items-center gap-1"
                    iconBefore="ic:outline-badge"
                    options={profileOptions}
                    errorMessage={error?.message}
                    onValueChange={onChange}
                  />
                )}
              />
              {watch('profession') === InvestorProfile.Professional ? (
                <>
                  <Controller
                    control={control}
                    name="declaredOrg"
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="declaredOrg"
                        {...field}
                        label="Organization"
                        iconBefore="ic:outline-business"
                        placeholder="Organization name"
                        errorMessage={error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                      <SelectField
                        id="country"
                        label={
                          <>
                            Country
                            <Tooltip
                              asChild
                              content={`Please select the country in which your company is incorporated.
                      
                      Currently, we are only able to serve requests from the countries given below. 
                      
                      If none of the given countries apply to you, or if you have any questions, please get in touch at support@arabesque.ai`}
                            >
                              <Icon className="flex-0" icon="ic:outline-info" />
                            </Tooltip>
                          </>
                        }
                        {...field}
                        onValueChange={onChange}
                        isFilterable
                        options={countryOptions}
                        placeholder="Select a country"
                        iconBefore="ic:outline-flag"
                        contentClassName="max-h-[408px]"
                        labelClassName="inline-flex items-center gap-1"
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </>
              ) : (
                <Alert level="warning">
                  We currently only accept requests from professional clients. If you have any questions, please get in
                  touch with us at support@arabesque.ai.
                </Alert>
              )}
            </div>
          </div>
          {watch('profession') === InvestorProfile.Professional && (
            <>
              <div>
                <Controller
                  control={control}
                  name="terms"
                  render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                    <Input.Wrapper>
                      <Checkbox
                        {...field}
                        checked={value}
                        onCheckedChange={confirm => onChange(Boolean(confirm))}
                        className="text-sm"
                        data-testid="ac-register-terms"
                      >
                        I have read and agree to the{' '}
                        <Link
                          className="font-medium text-black underline underline-offset-4"
                          to={`${getDragonURL}/platform-service-terms`}
                          target="_blank"
                        >
                          Terms and Conditions.
                        </Link>
                      </Checkbox>
                      {error && <Input.ErrorMessage>{error.message}</Input.ErrorMessage>}
                    </Input.Wrapper>
                  )}
                />
              </div>
              <Controller
                control={control}
                name="captchaResponse"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Turnstile
                      ref={turnstileRef}
                      siteKey={process.env.NX_CF_TURNSTILE_SITE_KEY || ''}
                      options={{ theme: 'light' }}
                      onSuccess={field.onChange}
                      onError={() => {
                        field.onChange('');
                        // @TODO: use react hook forms to set error
                        setError({ message: 'Captcha validation failed' });
                      }}
                      onExpire={() => {
                        field.onChange('');
                        turnstileRef.current?.reset();
                      }}
                    />
                    <span className="text-sm text-error-500">{error?.message}</span>
                  </>
                )}
              />
              {error && <ApiErrorAlert error={error} />}
              <div>
                <Button
                  data-testid="ac-register-send"
                  loading={isSubmitting}
                  loadingText="Sending request..."
                  type="submit"
                >
                  Send request to access
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </Card>
  );
};
