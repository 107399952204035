import * as React from 'react';
import { ConsentBanner } from '@aether/tracking/consent-banner';
import { initCustomIcons } from '@aether/ui/Icon';
import { Spinner } from '@aether/ui/Spinner';
import { ToastController } from '@aether/ui/Toast';
import { UiProvider } from '@aether/ui/UiProvider';
import { Chart, registerables } from 'chart.js';
import { RouterProvider } from 'react-router-dom';

import { PageSeoTitle } from './components/PageSeoTitle';
import { FeatureController } from './controllers/FeatureController';
import { router } from './router';
import { AuthProvider } from './utils/auth';

initCustomIcons();

Chart.register(...registerables);
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.plugins.tooltip = {
  ...Chart.defaults.plugins.tooltip,
  caretSize: 12,
  padding: 14,
  displayColors: false,
  bodyColor: '#000',
  titleColor: '#000',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderColor: 'rgba(0, 0, 0, 0.2)',
  borderWidth: 1,
};
Chart.defaults.font.family = `'Geist', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`;

export function App() {
  return (
    <>
      <PageSeoTitle title="Arabesque - Portfolio Dragon" />
      <FeatureController />
      <ToastController />
      <AuthProvider
        loadingPlaceholder={
          <div className="flex h-screen w-full items-center justify-center">
            {/* do not replace this with a Card component in loading state. */}
            <Spinner />
          </div>
        }
      >
        <UiProvider>
          <RouterProvider router={router} />
          <ConsentBanner />
        </UiProvider>
      </AuthProvider>
    </>
  );
}
