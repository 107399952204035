import { ButtonNew } from '@aether/ui/ButtonNew';
import { Card } from '@aether/ui/Card';
import { Link } from 'react-router-dom';

import { PageTitle } from '@/components/PageHeader';
import { Login } from '@/pages/Auth/Login/Login';
import { useAuth } from '@/utils/auth';

type DemoPageType =
  | 'performance'
  | 'sustainability'
  | 'composition'
  | 'holdings'
  | 'drawdown'
  | 'turnover'
  | 'attribution';

export const RestrictedPage = ({ type }: { type?: DemoPageType }) => {
  const { isAuthenticated } = useAuth();
  return (
    <div
      className="h-full w-full overflow-hidden bg-cover"
      style={type ? { backgroundImage: `url(/images/demo/${type}.jpg)` } : {}}
    >
      <div>{isAuthenticated ? <RestrictedMessage /> : <Login />}</div>
    </div>
  );
};

function RestrictedMessage() {
  return (
    <Card variant="bordered" className="m-20 max-w-md sm:mx-auto">
      <div className="flex flex-col gap-4">
        <PageTitle>Trial expired</PageTitle>
        <p>You need to upgrade your account to access this feature.</p>
        <p className="text-center">
          <ButtonNew asChild>
            <Link to="/account/plan">Upgrade</Link>
          </ButtonNew>
        </p>
      </div>
    </Card>
  );
}
