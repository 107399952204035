import { PageHeader, PageTitle } from '@/components/PageHeader';
import { WhenI } from '@/components/Permissions/WhenI';

import { RouterError } from '../Error/RouterError';
import { RestrictedPage } from '../StrategyDetails/components/RestrictedPage';

export const configurationRouter = {
  lazy: async () => {
    const { ConfigurationLayout } = await import('./ConfigurationLayout');
    const Component = () => (
      <WhenI
        can="read"
        the="ConfigurationPage"
        show={<ConfigurationLayout />}
        otherwiseShow={
          <div>
            <PageHeader>
              <PageTitle className="my-4">Configuration</PageTitle>
            </PageHeader>
            <RestrictedPage />
          </div>
        }
      />
    );
    Component.displayName = 'ConfigurationPage';
    return {
      Component,
      ErrorBoundary: () => <RouterError />,
    };
  },
};
