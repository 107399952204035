import { useEffect, useState } from 'react';
import { tracker } from '@aether/tracking';
import { Alert } from '@aether/ui/Alert';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { Card } from '@aether/ui/Card';
import { Checkbox } from '@aether/ui/Checkbox';
import { Spinner } from '@aether/ui/Spinner';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { graphql, useQuery } from '@/graphql';
import { getDirectApolloClient } from '@/graphql/client';
import { useAuth } from '@/utils/auth';

export function AcceptInvitePage() {
  const { signInWithInviteCode, requestNewInvite } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code')!;
  const email = searchParams.get('email');

  useEffect(() => {
    if (email) tracker.identify(email);
  }, [email]);

  const {
    data: verifyData,
    loading: verifyingToken,
    error: verifyError,
  } = useQuery(verifyInviteCodeQuery, {
    variables: { code: code || '' },
    skip: !code,
    client: getDirectApolloClient(),
    onCompleted: data => {
      if (data.verifyInviteCode.success === false) {
        tracker.capture('invite_validation_error', { error: data.verifyInviteCode.error });
      }
    },
  });

  const [tcAccepted, setTcAccepted] = useState(false);
  const [newEmailSent, setNewEmailSent] = useState(false);

  const verifySuccess = verifyData?.verifyInviteCode.success;
  const verifyMessage = verifyData?.verifyInviteCode.error;

  const [error, setError] = useState<any>();

  const [loading, setLoading] = useState(false);

  if (!code || !email) {
    return (
      <div className="m-20 -mx-4 max-w-4xl sm:mx-auto">
        <Alert level="error" title="Missing params">
          <ul className="list-inside list-disc">
            {!code && <li>missing invite code</li>}
            {!email && <li>missing invitee email</li>}
          </ul>
        </Alert>
      </div>
    );
  }

  const signIn = async () => {
    setLoading(true);
    setError(undefined);
    try {
      await signInWithInviteCode(code);
      tracker.capture('invite_success');
      navigate('/account/complete-signup', { replace: true });
    } catch (e: any) {
      tracker.capture('invite_error', { error: JSON.stringify(e) });
      setError(e);
      setLoading(false);
    }
  };

  const handleRequestNewInvite = async () => {
    if (!email) return;
    tracker.capture('get_new_invite');
    setError(undefined);
    setLoading(true);
    try {
      await requestNewInvite(email);

      tracker.capture('get_new_invite_success');
      setNewEmailSent(true);
    } catch (e: any) {
      setError(e);
      tracker.capture('get_new_invite_error', { error: JSON.stringify(e) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card variant="hero" title="Sign up to Portfolio Dragon." className="m-20 -mx-4 max-w-4xl sm:mx-auto">
      {verifyingToken && (
        <div className="mt-10 flex items-center gap-2">
          <Spinner className="h-5 w-5" />
          <p className="text-base">Checking invitation link validity...</p>
        </div>
      )}
      {verifyError && (
        <div className="mt-10 flex items-center gap-2">
          <ApiErrorAlert error={verifyError} />
        </div>
      )}
      {verifySuccess === false && (
        <div className="mt-8 space-y-4">
          {verifyMessage?.toLowerCase().includes('invalid') && (
            <>
              <Alert level="error">
                This invitation link is invalid. You can still create an account without a valid invitation link.
              </Alert>
              <div className="space-x-2">
                <ButtonNew asChild>
                  <Link to="/auth/login?redirectTo=%2Fwelcome">Go to login form</Link>
                </ButtonNew>
                <ButtonNew asChild variant="secondary">
                  <Link to="/auth/register?redirectTo=%2Fwelcome">Create an account</Link>
                </ButtonNew>
              </div>
            </>
          )}
          {verifyMessage?.toLowerCase().includes('accepted') && (
            <>
              <Alert level="warning">This invitation has already been accepted. Please log into your account.</Alert>
              <ButtonNew asChild>
                <Link to="/auth/login?redirectTo=%2Fwelcome">Go to login form</Link>
              </ButtonNew>
            </>
          )}
          {verifyMessage?.toLowerCase().includes('expired') && (
            <>
              {newEmailSent ? (
                <Alert level="success">
                  A new link has been sent to your email address, please check your inbox and spam folder.
                </Alert>
              ) : (
                <Alert level="warning" title="Link expired">
                  It looks like the sign-up link is already expired. No worries, we can send a new one right away.
                </Alert>
              )}
              {error ? (
                <>
                  <ApiErrorAlert error={error} />
                  <ButtonNew asChild>
                    <Link to="/auth/login?redirectTo=%2Fwelcome">Go to login form</Link>
                  </ButtonNew>
                </>
              ) : (
                <ButtonNew onClick={handleRequestNewInvite} loading={loading} loadingText="Sending...">
                  Send me a new link
                </ButtonNew>
              )}
            </>
          )}
        </div>
      )}
      {verifySuccess === true && (
        <div className="mt-8 space-y-4">
          <p>
            Please read & agree to our{' '}
            <a
              href="https://portfoliodragon.com/platform-service-terms"
              target="_blank"
              className="font-medium text-black underline underline-offset-4"
            >
              service terms
            </a>{' '}
            to accept the invitation and sign up to the web app.
          </p>
          <p>
            <Checkbox
              checked={tcAccepted}
              onCheckedChange={checked => setTcAccepted(Boolean(checked))}
              className="text-sm"
            >
              I have read and agree to the service terms
            </Checkbox>
          </p>
          <div>
            <ButtonNew onClick={signIn} disabled={!tcAccepted} loading={loading} loadingText="Signing up...">
              Accept invitation & Sign up
            </ButtonNew>
          </div>
          {error && <ApiErrorAlert error={error} />}
        </div>
      )}
    </Card>
  );
}

const verifyInviteCodeQuery = graphql(/* GraphQL */ `
  query VerifyInviteCodeQuery($code: String!) {
    verifyInviteCode(code: $code) {
      success
      error
    }
  }
`);
