import * as React from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Input } from '../Input';
import { cn, useFilter, useForwardedRef, useId } from '../utils';

export type SelectFieldProps<T extends string, O extends SelectFieldOption<T> | GroupedSelectFieldOption<T>> = Omit<
  RadixSelect.SelectProps,
  'children'
> &
  VariantProps<typeof triggerStyle> & {
    id?: string;
    label?: React.ReactNode;
    errorMessage?: string;
    iconBefore?: string;
    placeholder?: string;
    isFilterable?: boolean;
    isClearable?: boolean;
    renderInPortal?: boolean;
    onFilterChange?: (filter: string) => void;
    options?: Readonly<O[]>;
    collisionPadding?: number;
    wrapperClassName?: string;
    labelClassName?: string;
    fieldClassName?: string;
    valueClassName?: string;
    placeholderClassName?: string;
    errorClassName?: string;
    contentClassName?: string;
    autoFocus?: boolean;
  };

export type SelectFieldOption<T extends string = string> = {
  value: T;
  label: string;
  description?: string;
  textValue?: string;
  className?: string;
  iconBefore?: string;
  iconAfter?: string;
} & VariantProps<typeof itemStyle>;

export type GroupedSelectFieldOption<T extends string = string> = {
  groupLabel: string;
  options: SelectFieldOption<T>[];
};

const isGroupedOption = <T extends string>(
  option: GroupedSelectFieldOption<T> | SelectFieldOption<T>
): option is GroupedSelectFieldOption<T> => {
  return 'groupLabel' in option;
};

const Select = function Select<T extends string, O extends SelectFieldOption<T> | GroupedSelectFieldOption<T>>(
  props: SelectFieldProps<T, O>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    label,
    errorMessage,
    isFilterable,
    isClearable,
    options = [],
    placeholder = 'Select',
    collisionPadding = 8,
    value,
    onValueChange,
    onFilterChange,
    renderInPortal = true,
    wrapperClassName,
    labelClassName,
    errorClassName,
    fieldClassName,
    valueClassName,
    placeholderClassName,
    contentClassName,
    iconBefore,
    variant,
    size,
    disabled,
    defaultOpen = false,
    required,
    autoFocus,
    ...rest
  } = props;

  const fieldId = useId(props.id);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { contains } = useFilter({
    sensitivity: 'base',
  });
  const filterFuncRef = React.useRef(contains); // "contains" is a new instance every render, so we need to put it in a ref to be able to memoize
  const [filter, setFilter] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
  const [open, onOpenChange] = React.useState(defaultOpen);

  const filteredOptions = React.useMemo(() => {
    return options.flatMap(option => {
      if (isGroupedOption(option)) {
        const filteredInGroup = option.options.filter(option =>
          filterFuncRef.current(option.textValue || option.label, filter)
        );
        return filteredInGroup.length === 0 ? [] : [{ ...option, options: filteredInGroup }];
      }
      return filterFuncRef.current(option.textValue || option.label, filter) ? [option] : [];
    });
  }, [options, filter]);
  const flatFilteredOptions = filteredOptions.flatMap(option => {
    if (isGroupedOption(option)) return option.options;
    else return [option as SelectFieldOption<T>];
  });
  const numberOfFilteredOptions = filteredOptions.reduce((count, option) => {
    count += isGroupedOption(option) ? option.options.length : 1;
    return count;
  }, 0);
  const numberOfOriginalOptions = options.reduce((count, option) => {
    count += isGroupedOption(option) ? option.options.length : 1;
    return count;
  }, 0);

  React.useEffect(() => {
    if (numberOfFilteredOptions !== numberOfOriginalOptions) {
      setSelectedIndex(0);
    }
  }, [numberOfFilteredOptions, numberOfOriginalOptions]);

  const onInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (propagateKeys.includes(e.key) === false) {
        e.stopPropagation();
      }
      if (e.key === 'ArrowDown') {
        e.stopPropagation();
        setSelectedIndex(i => {
          if (i === undefined) return 0;
          return Math.min(i + 1, numberOfFilteredOptions - 1);
        });
      }
      if (e.key === 'ArrowUp') {
        e.stopPropagation();
        setSelectedIndex(i => {
          if (i === undefined) return 0;
          return Math.max(i - 1, 0);
        });
      }
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (selectedIndex !== undefined) {
          onValueChange?.(flatFilteredOptions[selectedIndex].value);
          setFilter('');
          setSelectedIndex(undefined);
          onOpenChange(false);
        }
      }
    },
    [flatFilteredOptions, numberOfFilteredOptions, onValueChange, selectedIndex]
  );

  const currentOption = React.useMemo(() => {
    return flatFilteredOptions.find(option => option.value === value);
  }, [value, flatFilteredOptions]);

  const innerOpenChange = React.useCallback((open: boolean) => {
    if (open) requestAnimationFrame(() => inputRef.current?.focus());
    if (open === false) {
      setFilter('');
      setSelectedIndex(undefined);
    }
    onOpenChange(open);
  }, []);

  const onFilteredItemSelect = (value: string) => {
    onValueChange?.(value);
    innerOpenChange(false);
  };

  // Either render RadixSelect.Portal or a normal Div element
  const Portal = renderInPortal ? RadixSelect.Portal : React.Fragment;

  return (
    <Input.Wrapper className={wrapperClassName}>
      {label && (
        <Input.Label htmlFor={fieldId} className={labelClassName}>
          {label}
        </Input.Label>
      )}
      <RadixSelect.Root
        {...rest}
        open={open}
        value={value}
        onValueChange={isFilterable ? () => undefined : onValueChange}
        onOpenChange={innerOpenChange}
      >
        <RadixSelect.Trigger
          ref={ref}
          id={fieldId}
          className={cn(triggerStyle({ disabled, variant, size }), fieldClassName)}
          aria-invalid={!!errorMessage}
          aria-errormessage={errorMessage ? `${fieldId}-error` : undefined}
          aria-required={required}
          disabled={disabled}
          autoFocus={autoFocus}
        >
          {iconBefore && <Icon className="flex-0 text-text-secondary" icon={iconBefore} />}
          <span className={cn('flex-1 flex-nowrap overflow-hidden text-ellipsis whitespace-nowrap', valueClassName)}>
            {currentOption?.label ?? (
              <span className={cn('text-grey-ds-500', placeholderClassName)}>{placeholder}</span>
            )}
          </span>
          {isClearable && value && (
            <IconButton
              size="xsmall"
              variant="ghost"
              variantColor="secondary"
              icon="ic:outline-close"
              onPointerDown={e => {
                // radix uses this event, so we need to stop it
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={() => onValueChange?.('')}
              onKeyDown={e => {
                if (['Enter', ' '].includes(e.key)) {
                  e.stopPropagation();
                  onValueChange?.('');
                }
              }}
              // as div to avoid button inside of button (invalid html)
              asChild
            >
              <div tabIndex={0} />
            </IconButton>
          )}
          <RadixSelect.Icon asChild>
            <Icon className="flex-0 text-base leading-4" icon="ic:outline-expand-more" />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <Portal {...(renderInPortal ? { className: 'z-50' } : {})}>
          <RadixSelect.Content
            sideOffset={2}
            collisionPadding={collisionPadding}
            position="popper"
            className={cn(
              'flex max-h-[var(--radix-select-content-available-height)] flex-col rounded bg-white p-2 shadow-dropdown',
              contentClassName
            )}
          >
            {isFilterable && (
              <Input.FieldGroup className="mb-1">
                <Icon className="ml-1 text-base" icon="ic:baseline-search" />
                <Input.Field
                  onKeyDown={onInputKeyDown}
                  ref={inputRef}
                  value={filter}
                  onChange={e => {
                    onFilterChange?.(e.target.value);
                    setFilter(e.target.value);
                  }}
                />
              </Input.FieldGroup>
            )}
            <RadixSelect.Viewport>
              {filteredOptions.map((option, index) => {
                if (isFilterable) {
                  if (isGroupedOption(option)) {
                    const indexOffset = filteredOptions.reduce((offset, option, currentIndex) => {
                      if (isGroupedOption(option) && currentIndex < index) offset += option.options.length;
                      return offset;
                    }, 0);
                    return (
                      <FilterableSelectItemGroup
                        key={`${option.groupLabel}${index}`}
                        {...option}
                        currentOption={currentOption}
                        onSelect={onFilteredItemSelect}
                        selectedIndex={selectedIndex}
                        indexOffset={indexOffset}
                        separator={index < filteredOptions.length - 1}
                      />
                    );
                  }
                  return (
                    <FilterableSelectItem
                      key={option.value}
                      {...option}
                      selected={index === selectedIndex}
                      isCurrent={option.value === currentOption?.value}
                      onSelect={onFilteredItemSelect}
                    />
                  );
                }
                return isGroupedOption(option) ? (
                  <SelectItemGroup
                    key={`${option.groupLabel}${index}`}
                    {...option}
                    separator={index < filteredOptions.length - 1}
                  />
                ) : (
                  <SelectItem key={option.value} {...option} />
                );
              })}
            </RadixSelect.Viewport>
          </RadixSelect.Content>
        </Portal>
      </RadixSelect.Root>
      {errorMessage && (
        <Input.ErrorMessage id={`${fieldId}-error`} className={errorClassName}>
          {errorMessage}
        </Input.ErrorMessage>
      )}
    </Input.Wrapper>
  );
};
export const SelectField = React.forwardRef(Select) as <
  T extends string = string,
  O extends SelectFieldOption<T> | GroupedSelectFieldOption<T> = SelectFieldOption<T>,
>(
  props: SelectFieldProps<T, O>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => ReturnType<typeof Select>;

const propagateKeys = ['Enter', 'Escape'];

const SelectItemGroup = React.forwardRef<HTMLDivElement, GroupedSelectFieldOption & { separator: boolean }>(
  function SelectItemGroup(props, forwardedRef) {
    const { groupLabel, options, separator } = props;
    return (
      <RadixSelect.Group ref={forwardedRef}>
        <RadixSelect.Label className={groupLabelStyle}>{groupLabel}</RadixSelect.Label>
        {options.map(option => (
          <SelectItem {...option} />
        ))}
        {separator && <RadixSelect.Separator className={separatorStyle} />}
      </RadixSelect.Group>
    );
  }
);

const SelectItem = React.forwardRef<HTMLDivElement, SelectFieldOption>(function SelectItem(props, forwardedRef) {
  const { label, description, className, unstyled, selected, ...rest } = props;
  return (
    <RadixSelect.Item ref={forwardedRef} className={cn(itemStyle({ unstyled, selected }), className)} {...rest}>
      <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
      {description && <span className="text-xs text-grey-ds-500">{description}</span>}
      <RadixSelect.ItemIndicator className="absolute left-0 mx-0.5 text-sm">
        <Icon icon="ic:outline-check" />
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
});

const FilterableSelectItemGroup = React.forwardRef<
  HTMLDivElement,
  GroupedSelectFieldOption & {
    onSelect?: (value: string) => void;
    currentOption?: SelectFieldOption;
    selectedIndex?: number;
    indexOffset: number;
    separator: boolean;
  }
>(function FilterableSelectItemGroup(props, forwardedRef) {
  const { groupLabel, options, onSelect, currentOption, selectedIndex, indexOffset, separator } = props;
  const ref = useForwardedRef(forwardedRef);
  return (
    <>
      <div ref={ref} className={groupLabelStyle}>
        {groupLabel}
      </div>
      {options.map((option, index) => (
        <FilterableSelectItem
          key={option.value}
          {...option}
          selected={index + indexOffset === selectedIndex}
          isCurrent={option.value === currentOption?.value}
          onSelect={onSelect}
        />
      ))}
      {separator && <div className={separatorStyle}></div>}
    </>
  );
});

const FilterableSelectItem = React.forwardRef<
  HTMLDivElement,
  SelectFieldOption & { onSelect?: (value: string) => void; isCurrent?: boolean }
>(function FilterableSelectItem(props, forwardedRef) {
  const { label, textValue, description, className, unstyled, selected, onSelect, value, isCurrent, ...rest } = props;
  const ref = useForwardedRef(forwardedRef);
  React.useEffect(() => {
    if (selected) ref.current?.scrollIntoView({ block: 'nearest' });
  }, [selected, ref]);
  return (
    <div
      ref={ref}
      className={cn(itemStyle({ unstyled, selected }), className)}
      onClick={e => {
        e.stopPropagation();
        onSelect?.(value);
      }}
      role="option"
      aria-label={textValue || label}
      {...rest}
    >
      {isCurrent && <Icon className="absolute left-0 mx-0.5 text-sm" icon="ic:outline-check" />}
      {label}
      {description && <span className="pt-0.5 text-xs text-grey-ds-500">{description}</span>}
    </div>
  );
});

const triggerStyle = cva('', {
  variants: {
    variant: {
      unstyled: '',
      primary:
        'flex items-center gap-2 rounded border border-border-medium bg-white px-2 py-[9px] text-left text-sm leading-4 outline-none focus:border-border-dark data-[state=open]:border-border-dark',
      secondary:
        'flex items-center gap-2 rounded bg-white px-2 py-2 text-left text-sm leading-5 shadow-card outline-none',
      flat: 'flex items-center gap-2',
    },
    disabled: {
      false: 'cursor-pointer',
      true: 'cursor-not-allowed',
    },
    size: {
      default: '',
      small: '',
      dense: 'h-6',
    },
  },
  defaultVariants: {
    size: 'default',
    variant: 'primary',
    disabled: false,
  },
});

const itemStyle = cva('cursor-pointer', {
  variants: {
    unstyled: {
      false: 'relative flex items-center gap-2 rounded-sm px-5 py-0.5 text-sm leading-6 outline-none',
    },
    selected: {
      true: 'bg-grey-50 text-black',
      false: 'text-grey-600 hover:bg-grey-50 hover:text-black focus-visible:bg-grey-50 focus-visible:text-black',
    },
  },
  defaultVariants: {
    unstyled: false,
    selected: false,
  },
});

const groupLabelStyle = 'px-5 py-0.5 text-sm text-grey-300';
const separatorStyle = 'border-b border-border-light';
