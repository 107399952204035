import {
  ApolloError,
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
  type ApolloCache,
  type DefaultContext,
  type DocumentNode,
  type LazyQueryHookOptions,
  type LazyQueryResultTuple,
  type MutationHookOptions,
  type MutationTuple,
  type NormalizedCacheObject,
  type OperationVariables,
  type QueryHookOptions,
  type QueryResult,
  type TypedDocumentNode,
} from '@apollo/client';

import { getProxiedApolloClient } from './client';

export * from '@aether/client-graphql/generated';

export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const client = getProxiedApolloClient();
  return useApolloQuery(query, { client, ...options });
}

export function useLazyQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): LazyQueryResultTuple<TData, TVariables> {
  const client = getProxiedApolloClient();
  return useApolloLazyQuery(query, { client, ...options });
}

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<NormalizedCacheObject>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>
): MutationTuple<TData, TVariables, TContext, TCache> {
  const client = getProxiedApolloClient();
  return useApolloMutation(mutation, { client, ...options });
}

export function parseApolloError(error: any): string | string[] {
  if (error instanceof ApolloError) {
    if (error.networkError) {
      if ('result' in error.networkError) {
        if (typeof error.networkError.result === 'string') {
          return error.networkError.result;
        }
        if ('errors' in error.networkError.result) {
          return error.networkError.result.errors.map((e: any) => e.message);
        }
        return JSON.stringify(error.networkError.result);
      }
      if ('bodyText' in error.networkError) {
        return error.networkError.bodyText;
      }
      return error.networkError.message;
    }
    if (error.graphQLErrors) {
      console.error(JSON.stringify(error.graphQLErrors, null, 2));
      return error.graphQLErrors.map((e: any) => e.message);
    }
    if (error.clientErrors) {
      console.error(JSON.stringify(error.clientErrors, null, 2));
      return error.clientErrors.map((e: any) => e.message);
    }
  }
  console.error(error);
  return error.message || JSON.stringify(error);
}
